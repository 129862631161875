// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalog-banja-180-js": () => import("./../src/pages/catalog/banja_180.js" /* webpackChunkName: "component---src-pages-catalog-banja-180-js" */),
  "component---src-pages-catalog-banja-220-js": () => import("./../src/pages/catalog/banja_220.js" /* webpackChunkName: "component---src-pages-catalog-banja-220-js" */),
  "component---src-pages-catalog-dacha-180-js": () => import("./../src/pages/catalog/dacha_180.js" /* webpackChunkName: "component---src-pages-catalog-dacha-180-js" */),
  "component---src-pages-catalog-dom-brevno-220-js": () => import("./../src/pages/catalog/dom_brevno_220.js" /* webpackChunkName: "component---src-pages-catalog-dom-brevno-220-js" */),
  "component---src-pages-catalog-dom-brus-135-x-200-js": () => import("./../src/pages/catalog/dom_brus_135x200.js" /* webpackChunkName: "component---src-pages-catalog-dom-brus-135-x-200-js" */),
  "component---src-pages-catalog-dom-s-plosk-kr-js": () => import("./../src/pages/catalog/dom_s_plosk_kr.js" /* webpackChunkName: "component---src-pages-catalog-dom-s-plosk-kr-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

